import { ColDef } from 'ag-grid-community'
import { FC, useCallback, useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import PaginationTable from '@shared/ag-grid/pagination-table/PaginationTable'
import { HasPermission, UserNotAllowed } from '@shared/components'
import ThreeDotMenu, {
  ThreeDotMenuOption,
} from '@shared/components/three-dot-menu/ThreeDotMenu'
import { useEnforceLogin, useFtpPortalHubCommunication } from '@shared/hooks'
import { EnumServiceName, Merchant } from '@shared/types'
import { formatDatetime, formatPhoneNumber, DataSource } from '@shared/utils'

// On this SampleGrid the api /merchants endpoint
// is being consumed to showcase how the real api
// is being used on Grids

export const SampleGrid: FC = () => {
  const { t } = useTranslation()
  const { setAppBarTitle } = useFtpPortalHubCommunication()
  const { user } = useEnforceLogin()
  const navigate = useNavigate()

  const mapMerchantStatusCode = (status_code: number | undefined) => {
    switch (status_code) {
      case 1:
        return t('service-portal.app-in-process')
      case 2:
        return t('service-portal.underwriting')
      case 3:
        return t('common.pending')
      case 4:
        return t('common.declined')
      case 5:
        return t('common.approved')
      case 6:
        return t('common.closed')
      case 7:
        return `${t('common.closed-by')} ${t('common.merchant')})`
      case 8:
        return `${t('common.closed-by')} ${t('common.risk')})`
      default:
        return t('common.unknown')
    }
  }

  const datasource = useMemo(() => new DataSource('merchants'), [])

  const defaultColDef = useMemo(
    () => ({
      resizable: true,
    }),
    []
  )

  const navigateToMerchantSampleView = useCallback(
    (companyId: string) => navigate(`/service/sample-view/${companyId}`),
    []
  )

  const handleRowClick = useCallback(
    (event) => {
      const rowData = event.data
      navigateToMerchantSampleView(rowData.company_id)
    },
    [navigateToMerchantSampleView]
  )

  const columnDefs: ColDef<Merchant>[] = useMemo(() => {
    return [
      {
        headerName: t('common.company-id'),
        field: 'company_id',
        floatingFilter: true,
        filter: 'agTextColumnFilter',
        sortable: true,
      },
      {
        headerName: t('common.mid'),
        field: 'merchant_number',
        floatingFilter: true,
        filter: 'agTextColumnFilter',
      },
      {
        headerName: t('common.dba'),
        field: 'dba_name',
        floatingFilter: true,
        filter: 'agTextColumnFilter',
      },
      {
        headerName: t('common.received'),
        field: 'received_ts',
        cellRenderer: (data) => {
          const timestamp = data.value
          return formatDatetime(timestamp, user.tz) || '-'
        },
      },
      {
        headerName: t('common.status'),
        field: 'status_code',
        floatingFilter: true,
        valueGetter: (params) =>
          mapMerchantStatusCode(params.data?.status_code),
      },
      {
        headerName: t('common.phone'),
        field: 'customer_service_phone',
        valueFormatter: function (params) {
          return formatPhoneNumber(params.value)
        },
      },
      {
        headerName: t('common.agent'),
        field: 'agent_id',
      },
      {
        floatingFilter: false,
        filter: false,
        resizable: false,
        sortable: false,
        cellRenderer: ({ data }) => {
          const options: ThreeDotMenuOption[] = [
            {
              label: t('common.view-details'),
              enabled: true,
              action: () => navigateToMerchantSampleView(data.company_id),
            },
            {
              label: t('service-portal.ui-samples'),
              enabled: true,
              action: () => navigate('/service/ui-samples'),
            },
          ]

          return <ThreeDotMenu options={options} />
        },
      },
    ]
  }, [user, navigateToMerchantSampleView])

  useEffect(() => {
    setAppBarTitle(t('service-portal.sample-grid-merchants'))
  }, [])

  return (
    <section>
      <HasPermission
        permission="v1.partner.merchants.get"
        unauthorizedComponent={<UserNotAllowed />}
      >
        <PaginationTable<Merchant>
          getRowId={(data) => data.data.company_id}
          defaultColDef={defaultColDef}
          columnDefs={columnDefs}
          rowModelType={'serverSide'}
          serverSideDatasource={datasource}
          onRowClicked={handleRowClick}
          serviceName={EnumServiceName.Merchants}
        />
      </HasPermission>
    </section>
  )
}
