import { yupResolver } from '@hookform/resolvers/yup'
import { FC, useEffect, useState } from 'react'
import { useForm, Controller } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { tss } from 'tss-react/mui'
import * as yup from 'yup'

import { AppBar, Box, Grid } from '@mui/material'

import { User } from '@shared/api'
import {
  ButtonBar,
  ButtonBarEnd,
  FieldGroupContainer,
  FieldGroupRow,
  HasPermission,
  PageLayoutContainer,
  UserNotAllowed,
} from '@shared/components'
import Button from '@shared/components/buttons/Button'
import Input from '@shared/components/form-fields/inputs/Input'
import Notification, {
  NotificationProps,
} from '@shared/components/notification/Notification'
import { useFtpPortalHubCommunication } from '@shared/hooks'

export interface SampleEditProps {
  user: User
}

export const SampleEdit: FC<SampleEditProps> = ({ user }) => {
  const { t } = useTranslation()
  const { setAppBarTitle } = useFtpPortalHubCommunication()

  const [notification, setNotification] = useState<Pick<
    NotificationProps,
    'type' | 'label'
  > | null>(null)

  useEffect(() => {
    setAppBarTitle(t('service-portal.sample-edit-form'))
  }, [])

  return (
    <HasPermission
      permission="v2.users.put"
      unauthorizedComponent={<UserNotAllowed />}
    >
      <>
        <Notification
          show={!!notification}
          type={notification?.type}
          label={notification?.label}
          onClose={() => setNotification(null)}
        />

        <SampleEditForm
          user={user}
          onSuccess={(data) => {
            // console.log({ data })

            setNotification({
              type: 'success',
              label: t('service-portal.user-updated-successfully'),
            })
          }}
          onError={(error) => {
            // console.log({ error })

            setNotification({
              type: 'error',
              label: t('common.something-went-wrong'),
            })
          }}
        />
      </>
    </HasPermission>
  )
}

interface EditFormData {
  first_name: string
  last_name: string
  email: string
}

interface SampleEditFormProps {
  user: User
  onSuccess?: (data: EditFormData) => void
  onError?: (error: unknown) => void
}

const useSampleEditFormStyles = tss.withName('SampleAdd').create(() => ({
  root: {
    width: '100%',
    display: 'grid',
    gridTemplateColumns: '1fr',
    placeContent: 'center',
    gap: '1em',
    padding: '1em',
  },
}))

export const SampleEditForm: FC<SampleEditFormProps> = ({
  user,
  onSuccess,
  onError,
}) => {
  const { t } = useTranslation()
  const { classes } = useSampleEditFormStyles()

  const [isLoading, setIsLoading] = useState(false)

  const schema = yup
    .object({
      first_name: yup
        .string()
        .required(t('common.validations.first-name-required'))
        .min(1),
      last_name: yup
        .string()
        .required(t('common.validations.last-name-required')),
      email: yup
        .string()
        .email()
        .required(t('common.validations.email-required')),
    })
    .required()

  const {
    handleSubmit,
    control,
    formState: { errors, isValid, isDirty },
  } = useForm({
    defaultValues: {
      first_name: user.first_name,
      last_name: user.last_name,
      email: user.email,
    },
    resolver: yupResolver(schema),
    mode: 'onSubmit',
  })

  const onSubmit = async (data: EditFormData) => {
    // console.log({ data })
    setIsLoading(true)

    // Simulate an API Call
    await new Promise((resolve) => {
      setTimeout(() => {
        resolve(null)
      }, 1000)
    })

    onSuccess?.(data)
    try {
    } catch (error) {
      onError?.(error)
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <form>
      <PageLayoutContainer isButtonBarAtBottom>
        <Box className={classes.root}>
          <FieldGroupContainer title={t('service-portal.user-info')}>
            <FieldGroupRow spacing={2}>
              <Grid item xs={12} md={6}>
                <Controller
                  name="first_name"
                  control={control}
                  render={({ field }) => (
                    <Input
                      {...field}
                      testId="first-name-input"
                      label={t('common.name-first')}
                      placeholder={t('common.name-first-placeholder')}
                      error={!!errors.first_name}
                      helperText={errors.first_name?.message}
                    />
                  )}
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <Controller
                  name="last_name"
                  control={control}
                  render={({ field }) => (
                    <Input
                      {...field}
                      testId="last-name-input"
                      label={t('common.name-last')}
                      placeholder={t('common.name-last-placeholder')}
                      required
                      error={!!errors.last_name}
                      helperText={errors.last_name?.message}
                    />
                  )}
                />
              </Grid>
            </FieldGroupRow>

            <FieldGroupRow>
              <Grid item xs={12} md={6}>
                <Controller
                  name="email"
                  control={control}
                  render={({ field }) => (
                    <Input
                      {...field}
                      testId="email-input"
                      label={t('common.email')}
                      placeholder={t('common.enter-email')}
                      required
                      error={!!errors.email}
                      helperText={errors.email?.message}
                    />
                  )}
                />
              </Grid>
            </FieldGroupRow>
          </FieldGroupContainer>
        </Box>

        <AppBar
          sx={{
            bottom: 0,
            top: 'auto',
            position: 'fixed',
            boxShadow: '0px -12px 79.9px 0px rgba(0, 0, 0, 0.10)',
          }}
        >
          <ButtonBar>
            <ButtonBarEnd>
              <Button
                testId="submit-button"
                label={t('common.submit')}
                type="submit"
                disabled={!isDirty}
                isLoading={isLoading}
                onClick={handleSubmit(onSubmit)}
              />
            </ButtonBarEnd>
          </ButtonBar>
        </AppBar>
      </PageLayoutContainer>
    </form>
  )
}
