import { createi18nInstance } from '@shared/i18n/src'

import Arrows from './arrows.json'
import English from './en.json'
import French from './fr.json'

export default createi18nInstance({
  resources: {
    en: English,
    fr: French,
    arrows: Arrows,
  },
})
