import { yupResolver } from '@hookform/resolvers/yup'
import { noop } from 'lodash'
import { FC, useEffect, useState } from 'react'
import {
  useForm,
  useFormContext,
  FormProvider,
  Controller,
} from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { tss } from 'tss-react/mui'
import * as yup from 'yup'

import { AppBar, Box, Typography } from '@mui/material'

import {
  ButtonBar,
  ButtonBarEnd,
  FieldGroupContainer,
  HasPermission,
  PageLayoutContainer,
  UserNotAllowed,
} from '@shared/components'
import Button from '@shared/components/buttons/Button'
import Input from '@shared/components/form-fields/inputs/Input'
import { SelectComponent as Select } from '@shared/components/form-fields/selects/Select'
import Notification, {
  NotificationProps,
} from '@shared/components/notification/Notification'
import {
  useFtpPortalHubCommunication,
  useSub,
  usePub,
  PubSubEvent,
} from '@shared/hooks'

// The purpose of this Form is to showcase an example of an Add Form
// and also the use of the Pub/Sub pattern through the shared useSub/usePub hooks.
//
// Some Form Fields will be intentionally rendered outside of the Form Context
// for us to communicate with them through Pub/Sub instead, here you have some
// explanation/rules that has been discussed before with the Dev Team about when
// Form Components communicate through Props vs Form Context vs Pub/Sub:

// To tell components what to do directly / adapt them to the different sections they're
// used on:

// - Props: Mostly used for flags to show/hide things on the Components to meet the requirements
// of different sections, or to make Controlled Components (like Inputs). Try your best to not
// share Form Data through them, we want to evade a complex "Prop Drilling" as much as possible.

// To communicate the Form Data:

// - Form Context: Used to manage the Form/Payload data, we want to keep it clean in the sense of not
// introducing on it data that isn't payload data / data that the server isn't interested about, when we
// talk about the Form Context we want to talk about a source that we can trust as having only payload data,
// also its the first priority to access the data we need if it's in there instead of using Pub/Sub.

// - Pub/Sub: Mostly used when the data we need can't be accessed through the Form Context, because otherwise
// we would make the Form Context "dirty" with data that doesn't belong to it, also it helps us to setup a clear
// intention of which is the data that we (Subscribers) are interested about, where does it come from (Publishers),
// and what we want to do with it, so its a very intuitive way of understanding how our logic works.

// Our best example about these rules is the implementation of the
// ftp-feature-mfe-gateway/src/components/virtual-terminal/VirtualTerminal.tsx

const FIRST_NAME_CHANGE_EVENT = new Event(
  'FIRST_NAME_CHANGE'
) as PubSubEvent<string>

const LAST_NAME_CHANGE_EVENT = new Event(
  'LAST_NAME_CHANGE'
) as PubSubEvent<string>

const FULL_NAME_INITIALS_CHANGE_EVENT = new Event(
  'FULL_NAME_INITIALS_CHANGE'
) as PubSubEvent<string>

const TRANSACTION_TYPE_CHANGE_EVENT = new Event(
  'TRANSACTION_TYPE_CHANGE'
) as PubSubEvent<TransactionType>

const TRANSACTION_TYPE_KEY_CHANGE_EVENT = new Event(
  'TRANSACTION_TYPE_KEY_CHANGE'
) as PubSubEvent<TransactionTypeKey>

type TransactionType = 'S' | 'AO' | 'AVSO' | 'R' | null
type TransactionTypeKey = `#${TransactionType}` | null

export const SampleAdd: FC = () => {
  const { t } = useTranslation()
  const { setAppBarTitle } = useFtpPortalHubCommunication()

  const [notification, setNotification] = useState<Pick<
    NotificationProps,
    'type' | 'label'
  > | null>(null)

  useEffect(() => {
    setAppBarTitle(t('service-portal.sample-add-form'))
  }, [])

  return (
    <HasPermission
      permission="v2.users.post"
      unauthorizedComponent={<UserNotAllowed />}
    >
      <>
        <Notification
          show={!!notification}
          type={notification?.type}
          label={notification?.label}
          onClose={() => setNotification(null)}
        />

        <SampleAddForm
          onSuccess={(data) => {
            // console.log({ data })

            setNotification({
              type: 'success',
              label: t('service-portal.data-create-successfully'),
            })
          }}
          onError={(error) => {
            // console.log({ error })

            setNotification({
              type: 'error',
              label: t('common.something-went-wrong'),
            })
          }}
        />
      </>
    </HasPermission>
  )
}

interface SampleFormData {
  first_name: string
  last_name: string
  full_name_initials: string
  transaction_type: TransactionType
  transaction_type_key: TransactionTypeKey
}

interface SampleAddFormProps {
  onSuccess?: (data: SampleFormData) => void
  onError?: (error: unknown) => void
}

const useSampleAddFormStyles = tss.withName('SampleAdd').create(() => ({
  root: {
    width: '100%',
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gap: '1em',
    padding: '1em',
  },
}))

export const SampleAddForm: FC<SampleAddFormProps> = ({
  onSuccess,
  onError,
}) => {
  const { t } = useTranslation()
  const { classes } = useSampleAddFormStyles()

  const [isLoading, setIsLoading] = useState(false)

  const schema = yup.object().shape({
    first_name: yup
      .string()
      .required(t('common.validations.first-name-required'))
      .min(1),
    last_name: yup
      .string()
      .required(t('common.validations.last-name-required'))
      .min(1),
    full_name_initials: yup.string().required(),
    transaction_type: yup.string().required(),
    transaction_type_key: yup.string().required(),
  })

  const defaultValues: SampleFormData = {
    first_name: '',
    last_name: '',
    full_name_initials: '',
    transaction_type: null,
    transaction_type_key: null,
  }

  const formMethods = useForm({
    defaultValues,
    resolver: yupResolver(schema),
    mode: 'onSubmit',
  })

  const onSubmit = async (data: SampleFormData) => {
    // console.log({ data })
    setIsLoading(true)

    try {
      // Simulate an API Call
      await new Promise((resolve) => {
        setTimeout(() => {
          resolve(null)
        }, 1000)
      })

      onSuccess?.(data)
    } catch (error) {
      onError?.(error)
    } finally {
      setIsLoading(false)
    }
  }

  // In this example some fields are out of the form context,
  // we'll communicate with them and update the form context
  // through the pub/sub mechanism.

  // Subscribe for full_name_initials changes and update the context accordingly.
  useSub(
    FULL_NAME_INITIALS_CHANGE_EVENT.type,
    (event: typeof FULL_NAME_INITIALS_CHANGE_EVENT) => {
      formMethods.setValue('full_name_initials', event.data)
      formMethods.trigger('full_name_initials')
    }
  )

  // Subscribe for transaction_type_key changes and update the context accordingly.
  useSub(
    TRANSACTION_TYPE_KEY_CHANGE_EVENT.type,
    (event: typeof TRANSACTION_TYPE_KEY_CHANGE_EVENT) => {
      formMethods.setValue('transaction_type_key', event.data)
      formMethods.trigger('transaction_type_key')
    }
  )

  return (
    <PageLayoutContainer isButtonBarAtBottom>
      <Box className={classes.root}>
        <Box>
          <FormProvider {...formMethods}>
            <form>
              <FieldGroupContainer title={t('service-portal.basic-info')}>
                <FirstNameInput />
                <LastNameInput />
                <TransactionTypeInput />
              </FieldGroupContainer>
            </form>
          </FormProvider>
        </Box>

        {/* 
          These are the Fields that are intentionally out of the Form Context for this example
          to showcase the Pub/Sub functionality.
          
          Normally Form Components do have access to the Form Context and following the rules described 
          at the top of this file. 
        */}
        <Box>
          <FieldGroupContainer title={t('service-portal.advanced-info')}>
            <FullNameInitialsInput />
            <TransactionTypeKeyInput />
          </FieldGroupContainer>
        </Box>
      </Box>

      <AppBar
        sx={{
          bottom: 0,
          top: 'auto',
          position: 'fixed',
          boxShadow: '0px -12px 79.9px 0px rgba(0, 0, 0, 0.10)',
        }}
      >
        <ButtonBar style={{ marginBottom: '0 !important' }}>
          <ButtonBarEnd>
            <Button
              testId="submit-button"
              label={t('common.submit')}
              onClick={formMethods.handleSubmit(onSubmit)}
              isLoading={isLoading}
            />
          </ButtonBarEnd>
        </ButtonBar>
      </AppBar>
    </PageLayoutContainer>
  )
}

const useInputStyles = tss.withName('EditUserProfile').create(({ theme }) => ({
  label: {
    color: `${theme.palette['neutral-700']} !important`,
    fontFamily: 'Inter !important',
    fontSize: '14px !important',
    fontWeight: '500 !important',
    lineHeight: '20px !important',
    paddingBottom: '.3em',
  },
  inputContainer: {
    paddingBottom: '1.5em',
  },
}))

const FirstNameInput = () => {
  const { t } = useTranslation()
  const { classes } = useInputStyles()

  const {
    control,
    formState: { errors },
  } = useFormContext<SampleFormData>()

  const publish = usePub()

  // The full_name_initials input needs to know about the updated first_name,
  // so we publish it.
  const onChange = (firstName: string) => {
    publish(FIRST_NAME_CHANGE_EVENT, firstName)
  }

  return (
    <Box className={classes.inputContainer}>
      <Controller
        name="first_name"
        control={control}
        render={({ field }) => (
          <Input
            {...field}
            testId="first-name-input"
            onChange={(event) => {
              field.onChange(event)
              onChange(event.target.value)
            }}
            label={t('common.name-first')}
            placeholder={t('common.name-first-placeholder')}
            required
            error={!!errors.first_name}
            helperText={errors.first_name?.message}
          />
        )}
      />
    </Box>
  )
}

const LastNameInput = () => {
  const { t } = useTranslation()
  const { classes } = useInputStyles()

  const {
    control,
    formState: { errors },
  } = useFormContext<SampleFormData>()

  const publish = usePub()

  // The full_name_initials input needs to know about the updated last_name,
  // so we publish it.
  const onChange = (lastName: string) => {
    publish(LAST_NAME_CHANGE_EVENT, lastName)
  }

  return (
    <Box className={classes.inputContainer}>
      <Controller
        name="last_name"
        control={control}
        render={({ field }) => (
          <Input
            {...field}
            testId="last-name-input"
            onChange={(event) => {
              field.onChange(event)
              onChange(event.target.value)
            }}
            label={t('common.name-last')}
            placeholder={t('common.name-last-placeholder')}
            required
            error={!!errors.last_name}
            helperText={errors.last_name?.message}
          />
        )}
      />
    </Box>
  )
}

// Remember that if this component would have access to the Form Context then it doesn't
// need to use Pub/Sub unless it wants to Publish/Subscribe to data that isn't part of
// the Form Context.
const FullNameInitialsInput = () => {
  const { t } = useTranslation()
  const { classes } = useInputStyles()
  const publish = usePub()

  // If having access to the Form Context the first_name and last_name can be obtained
  // like this instead:
  //
  // const { watch } = useForm<SampleFormData>()
  // const firstName = watch('first_name')
  // const lastName = watch('last_name')
  //
  // Neither the below "firstName"/"lastName" states or the Events Subscriptions would be needed.

  const [firstName, setFirstName] = useState<string>('')
  const [lastName, setLastName] = useState<string>('')

  const getFullNameInitials = (firstName?: string, lastName?: string) => {
    if (firstName || lastName) {
      return `${firstName ? firstName[0].toUpperCase() : ''}.${
        lastName ? lastName[0].toUpperCase() : ''
      }`
    }

    return ''
  }

  // Subscribe for first_name changes.
  useSub(
    FIRST_NAME_CHANGE_EVENT.type,
    (event: typeof FIRST_NAME_CHANGE_EVENT) => {
      setFirstName(event.data)

      // Now the form needs to know about the updated full_name_initials
      publish(
        FULL_NAME_INITIALS_CHANGE_EVENT,
        getFullNameInitials(event.data, lastName)
      )
    }
  )

  // Subscribe for last_name changes.
  useSub(
    LAST_NAME_CHANGE_EVENT.type,
    (event: typeof LAST_NAME_CHANGE_EVENT) => {
      setLastName(event.data)

      // Now the form needs to know about the updated full_name_initials
      publish(
        FULL_NAME_INITIALS_CHANGE_EVENT,
        getFullNameInitials(firstName, event.data)
      )
    }
  )

  return (
    <Box className={classes.inputContainer}>
      <Input
        testId="full-name-initials-input"
        onChange={noop}
        label={t('service-portal.full-name-initials')}
        placeholder=""
        value={getFullNameInitials(firstName, lastName)}
        disabled
      />
    </Box>
  )
}

const TransactionTypeInput = () => {
  const { t } = useTranslation()
  const { classes } = useInputStyles()
  const { control } = useFormContext()
  const publish = usePub()

  // The transaction_type_key input needs to know about the updated
  // transaction_type, so we publish it.
  const onSelectChange = (transactionType: TransactionType) => {
    publish(TRANSACTION_TYPE_CHANGE_EVENT, transactionType)
  }

  return (
    <Box className={classes.inputContainer}>
      <Controller
        name="transaction_type"
        control={control}
        render={({ field }) => (
          <Box>
            <span>
              <Typography className={classes.label}>
                {t('common.transaction-type')}
              </Typography>
            </span>

            <Select
              {...field}
              testId="transaction-type-select-input"
              onChange={(event) => {
                field.onChange(event)
                onSelectChange(event.target.value as TransactionType)
              }}
              options={[
                {
                  value: 'S',
                  label: t('common.sale'),
                },
                {
                  value: 'AO',
                  label: t('common.auth-only'),
                },
                {
                  value: 'AVSO',
                  label: t('common.avsonly'),
                },
                {
                  value: 'R',
                  label: t('common.refund'),
                },
              ]}
              style={{
                padding: '12px 0',
                width: '100%',
                maxWidth: 'unset',
                border: 'unset',
              }}
              required
            />
          </Box>
        )}
      />
    </Box>
  )
}

// Remember that if this component would have access to the Form Context then it doesn't
// need to use Pub/Sub unless it wants to Publish/Subscribe to data that isn't part of
// the Form Context.
const TransactionTypeKeyInput = () => {
  const { t } = useTranslation()
  const { classes } = useInputStyles()
  const publish = usePub()

  // If having access to the Form Context the transaction_type_key can be obtained
  // like this instead:
  //
  // const { watch } = useForm<SampleFormData>()
  // const transactionTypeKey = watch('transaction_type_key')
  //
  // Neither the below "transactionTypeKey" state or the Event Subscription would be needed.

  const [transactionTypeKey, setTransactionTypeKey] =
    useState<TransactionTypeKey | null>(null)

  // Subscribe for transaction_type changes.
  useSub(
    TRANSACTION_TYPE_CHANGE_EVENT.type,
    (event: typeof TRANSACTION_TYPE_CHANGE_EVENT) => {
      const transactionTypeKey = `#${event.data}` as TransactionTypeKey

      setTransactionTypeKey(transactionTypeKey)
      // Now the form needs to know about the updated transaction_type_key.
      publish(TRANSACTION_TYPE_KEY_CHANGE_EVENT, transactionTypeKey)
    }
  )

  return (
    <Box className={classes.inputContainer}>
      <Input
        testId="transaction-type-key-input"
        onChange={noop}
        label={`${t('common.transaction-type')} ${t('common.key')}`}
        placeholder=""
        value={transactionTypeKey ?? ''}
        disabled
      />
    </Box>
  )
}
