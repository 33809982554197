import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { tss } from 'tss-react/mui'

import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material'

import {
  Button,
  FieldGroupContainer,
  FieldGroupViewItem,
  Transition,
} from '@shared/components'

// The trick behind this component, is that actually it covers the whole screen, but it is positioned to the right with a fixed width.
// Using the absolute position we can put it anywhere we want. In this case on the extreme right.
const useStyles = tss.withName('SampleSlideOutModal').create(() => ({
  paper: {
    minWidth: '400px',
    maxWidth: '448px',
    position: 'absolute',
    right: 0,
    top: 0,
  },
  actions: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  sectionContainer: {
    backgroundColor: 'rgb(243 244 246 / 1)',
    border: '1px solid rgb(229 231 235 / 1)',
    borderRadius: '.5em',
    padding: '16px',
  },
}))

// We handle the open status from outside the modal.
// We do the same with the callback functions.
interface SampleSlideOutModalProps {
  isModalOpen: boolean
  onAction: () => void
  onClose: () => void
}

export const SampleSlideOutModal: FC<SampleSlideOutModalProps> = ({
  isModalOpen,
  onAction,
  onClose,
}) => {
  const { t } = useTranslation()
  const { classes } = useStyles()

  // Usually dialogs have three main sections: title, content and actions. Material UI provides components for each of them.

  // On the content we could have anything we want, actions, forms, etc. Usually we just display information.
  return (
    <Dialog
      fullScreen
      open={isModalOpen}
      onClose={onClose}
      TransitionComponent={Transition} // This shared transition component adds the slide effect from the left. This could be customized for different variants.
      PaperProps={{
        className: classes.paper,
      }}
      aria-labelledby="sample-slide-out-dialog"
      data-testid="sample-slide-out-dialog"
    >
      <DialogTitle>{t('service-portal.sample-slide-out-modal')}</DialogTitle>

      <DialogContent>
        <FieldGroupContainer title={t('service-portal.first-section')}>
          <FieldGroupViewItem
            isFullWidth
            isValueAlignRight
            title={`${t('service-portal.field')} 1`}
            value="Value 1"
          />
          <FieldGroupViewItem
            isFullWidth
            isValueAlignRight
            title={`${t('service-portal.field')} 2`}
            value="Value 2"
          />
          <FieldGroupViewItem
            isFullWidth
            isValueAlignRight
            title={`${t('service-portal.field')} 3`}
            value="Value 3"
          />
        </FieldGroupContainer>

        <Box className={classes.sectionContainer}>
          <FieldGroupContainer title={t('service-portal.second-section')}>
            <FieldGroupViewItem
              isFullWidth
              isValueAlignRight
              title={`${t('service-portal.field')} 1`}
              value="Value 1"
            />
            <FieldGroupViewItem
              isFullWidth
              isValueAlignRight
              title={`${t('service-portal.field')} 2`}
              value="Value 2"
            />
            <FieldGroupViewItem
              isFullWidth
              isValueAlignRight
              title={`${t('service-portal.field')} 3`}
              value="Value 3"
            />
            <Button
              label={t('service-portal.extra-button')}
              style={{
                width: '100%',
                marginTop: '16px',
              }}
            />
          </FieldGroupContainer>
        </Box>

        <FieldGroupContainer title={t('service-portal.third-section')}>
          <FieldGroupViewItem
            isFullWidth
            isValueAlignRight
            title={`${t('service-portal.field')} 1`}
            value="Value 1"
          />
          <FieldGroupViewItem
            isFullWidth
            isValueAlignRight
            title={`${t('service-portal.field')} 2`}
            value="Value 2"
          />
          <FieldGroupViewItem
            isFullWidth
            isValueAlignRight
            title={`${t('service-portal.field')} 3`}
            value="Value 3"
          />
        </FieldGroupContainer>
      </DialogContent>

      <DialogActions className={classes.actions}>
        <Button
          onClick={onClose}
          label={t('common.close')}
          style={{
            width: '50%',
          }}
          color="secondary"
        />
        <Button
          onClick={onAction}
          label={t('service-portal.action')}
          style={{
            width: '50%',
          }}
        />
      </DialogActions>
    </Dialog>
  )
}
