import { yupResolver } from '@hookform/resolvers/yup'
import { FC, SyntheticEvent, useState } from 'react'
import { useForm, Controller, SubmitHandler } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { tss } from 'tss-react/mui'
import * as yup from 'yup'

import { Grid, Typography } from '@mui/material'

import { ActionModal } from '@shared/components'
import Button from '@shared/components/buttons/Button'
import Input from '@shared/components/form-fields/inputs/Input'
import { useNotification } from '@shared/hooks'

const useSampleFormModalStyles = tss
  .withName('SampleFormModal')
  .create(({ theme }) => ({
    description: {
      fontFamily: 'Inter',
      fontSize: '14px',
      fontWeight: '400',
      lineHeight: '20px',
      color: theme.palette['neutral-600'],
      marginBottom: '1em',
    },
  }))

interface SampleFormData {
  name: string
}

export interface SampleFormModalProps {
  open: boolean
  onClose: () => void | SyntheticEvent<any, Event>
  onSuccess?: (data: SampleFormData) => void
  onError?: (error: unknown) => void
}

const SampleFormModal: FC<SampleFormModalProps> = ({
  open,
  onClose,
  onSuccess,
  onError,
}) => {
  const { t } = useTranslation()
  const { classes } = useSampleFormModalStyles()

  const [isLoading, setIsLoading] = useState<boolean>(false)

  const schema = yup.object().shape({
    name: yup.string().required(t('common.validations.name-required')).min(1),
  })

  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    defaultValues: {
      name: '',
    },
    resolver: yupResolver(schema),
    mode: 'onSubmit',
  })

  const onSubmit: SubmitHandler<SampleFormData> = async (data) => {
    setIsLoading(true)

    try {
      // Simulate an API Call
      await new Promise((resolve) => {
        setTimeout(() => {
          resolve(null)
        }, 1000)
      })

      closeModal()
      onSuccess?.(data)
    } catch (error) {
      onError?.(error)
    } finally {
      setIsLoading(false)
    }
  }

  const closeModal = () => {
    reset()
    onClose()
  }

  return (
    <ActionModal
      open={open}
      onClose={closeModal}
      title={t('service-portal.sample-form-modal')}
      buttons={[
        {
          testId: 'cancel-button',
          label: t('common.cancel'),
          color: 'secondary',
          onClick: closeModal,
        },
        {
          testId: 'send-name-button',
          label: t('service-portal.send-name'),
          isLoading: isLoading,
          onClick: handleSubmit(onSubmit),
        },
      ]}
    >
      <form>
        <Typography className={classes.description}>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin quis
          elit elementum, dictum nunc eget, gravida sapien. Integer accumsan
          vestibulum tellus id vestibulum. Donec nunc ante, consectetur ut metus
          ac, iaculis commodo enim. Donec efficitur ligula nec neque efficitur,
          eget vulputate arcu bibendum.
        </Typography>

        <Controller
          name="name"
          control={control}
          render={({ field }) => (
            <Input
              {...field}
              testId="name-input"
              label={t('common.name')}
              placeholder={t('common.name-placeholder')}
              error={!!errors.name}
              helperText={errors.name?.message}
            />
          )}
        />
      </form>
    </ActionModal>
  )
}

export const SampleModal: FC = () => {
  const { t } = useTranslation()

  const [openSampleFormModal, setOpenSampleFormModal] = useState(false)

  const { setNotification } = useNotification()

  return (
    <>
      <Grid>
        <Button
          testId="open-sample-form-modal-button"
          label={t('service-portal.open-sample-form-modal')}
          onClick={() => setOpenSampleFormModal(true)}
        />

        <SampleFormModal
          open={openSampleFormModal}
          onClose={() => setOpenSampleFormModal(false)}
          onSuccess={(data) => {
            // console.log({ data })

            setNotification({
              type: 'success',
              label: t('service-portal.name-sent-successfully'),
            })
          }}
          onError={(error) => {
            // console.log({ error })

            setNotification({
              type: 'error',
              label: t('common.something-went-wrong'),
            })
          }}
        />
      </Grid>
    </>
  )
}
