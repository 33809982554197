import { FC, useEffect, useState } from 'react'
import { useForm, Controller, SubmitHandler } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { tss } from 'tss-react/mui'

import { AppBar, Box, Grid, Typography } from '@mui/material'

import { api } from '@shared/api'
import {
  ButtonBar,
  ButtonBarEnd,
  Checkbox,
  FieldGroupContainer,
  FieldGroupRow,
  Loading,
  PageLayoutContainer,
} from '@shared/components'
import Button from '@shared/components/buttons/Button'
import Input from '@shared/components/form-fields/inputs/Input'
import {
  SelectComponent as Select,
  SelectOption,
} from '@shared/components/form-fields/selects/Select'
import { useFtpPortalHubCommunication } from '@shared/hooks'

const useStyles = tss
  .withName('ReactTestingLibrarySamples')
  .create(({ theme }) => ({
    root: {
      width: '100%',
      display: 'grid',
      gridTemplateColumns: '1fr',
      placeContent: 'center',
      gap: '1em',
      padding: '1em',
    },
    label: {
      color: `${theme.palette['neutral-700']} !important`,
      fontFamily: 'Inter !important',
      fontSize: '14px !important',
      fontWeight: '500 !important',
      lineHeight: '20px !important',
      paddingBottom: '.3em',
    },
  }))

export enum SelectOptionValue {
  'A' = 'A',
  'B' = 'B',
  'C' = 'C',
}

type FormData = {
  input_a: string
  input_b: string
  input_c: string

  select_a: SelectOptionValue
  select_b: SelectOptionValue
  select_c: SelectOptionValue

  checkbox_a: boolean
  checkbox_b: boolean
  checkbox_c: boolean
}

export const ReactTestingLibrarySamples: FC = () => {
  const { t } = useTranslation()
  const { classes } = useStyles()
  const { setAppBarTitle } = useFtpPortalHubCommunication()

  const [isLoading, setIsLoading] = useState(false)

  const mockSelectOptions: SelectOption<SelectOptionValue>[] = [
    { label: t('common.option'), value: SelectOptionValue.A },
    { label: t('common.option'), value: SelectOptionValue.B },
    { label: t('common.option'), value: SelectOptionValue.C },
  ]

  const formMethods = useForm<FormData>({
    defaultValues: {
      select_a: SelectOptionValue.A,
      select_b: SelectOptionValue.B,
      select_c: SelectOptionValue.C,
    },
    mode: 'onSubmit',
  })
  const { control, handleSubmit } = formMethods

  const onSubmit: SubmitHandler<FormData> = async (data) => {
    setIsLoading(true)

    if (process.env.NODE_ENV !== 'test') {
      // eslint-disable-next-line
      console.log({ data })
    }

    // This Api Method is called here to be used as an example
    // in the Test Suite about how to use jest.mock and jest.requireActual
    await api
      .service('users')
      .authorize()
      .finally(() => setIsLoading(false))
  }

  useEffect(() => {
    setAppBarTitle(t('service-portal.react-testing-library-samples'))
  }, [])

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <PageLayoutContainer>
        <AppBar>
          <ButtonBar>
            <ButtonBarEnd>
              <Button
                testId="submit-button"
                type="submit"
                label={t('common.submit')}
              />
            </ButtonBarEnd>
          </ButtonBar>
        </AppBar>

        <Box className={classes.root}>
          {isLoading && <Loading />}

          {!isLoading && (
            <>
              <FieldGroupContainer title={t('service-portal.text-inputs')}>
                <FieldGroupRow spacing={2}>
                  <Grid item xs={12} md={6}>
                    <Controller
                      name="input_a"
                      control={control}
                      render={({ field }) => (
                        <Input
                          {...field}
                          id="#input-a"
                          testId="input-a"
                          label={`${t('service-portal.input')} A`}
                          placeholder={`${t('service-portal.enter-input')} A`}
                        />
                      )}
                    />
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <Controller
                      name="input_b"
                      control={control}
                      render={({ field }) => (
                        <Input
                          {...field}
                          testId="input-b"
                          label={`${t('service-portal.input')} B`}
                          placeholder={`${t('service-portal.enter-input')} B`}
                        />
                      )}
                    />
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <Controller
                      name="input_c"
                      control={control}
                      render={({ field }) => (
                        <Input
                          {...field}
                          testId="input-c"
                          label={`${t('service-portal.input')} C`}
                          placeholder={`${t('service-portal.enter-input')} C`}
                        />
                      )}
                    />
                  </Grid>
                </FieldGroupRow>
              </FieldGroupContainer>

              <FieldGroupContainer title={t('service-portal.selects')}>
                <FieldGroupRow spacing={2}>
                  <Grid item xs={12} md={6}>
                    <Controller
                      name="select_a"
                      control={control}
                      render={({ field }) => (
                        <Box>
                          <Typography
                            className={classes.label}
                            component="span"
                          >
                            {`${t('common.select')} A`}
                          </Typography>

                          <Select
                            {...field}
                            testId="select-a"
                            options={mockSelectOptions}
                          />
                        </Box>
                      )}
                    />
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <Controller
                      name="select_b"
                      control={control}
                      render={({ field }) => (
                        <Box>
                          <Typography
                            className={classes.label}
                            component="span"
                          >
                            {`${t('common.select')} B`}
                          </Typography>

                          <Select
                            {...field}
                            testId="select-b"
                            options={mockSelectOptions}
                          />
                        </Box>
                      )}
                    />
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <Controller
                      name="select_c"
                      control={control}
                      render={({ field }) => (
                        <Box>
                          <Typography
                            className={classes.label}
                            component="span"
                          >
                            {`${t('common.select')} C`}
                          </Typography>

                          <Select
                            {...field}
                            testId="select-c"
                            options={mockSelectOptions}
                          />
                        </Box>
                      )}
                    />
                  </Grid>
                </FieldGroupRow>
              </FieldGroupContainer>

              <FieldGroupContainer title={t('service-portal.checkboxes')}>
                <FieldGroupRow spacing={2}>
                  <Grid item xs={12} md={6}>
                    <Controller
                      name="checkbox_a"
                      control={control}
                      render={({ field }) => (
                        <Checkbox
                          testId="checkbox-a"
                          label={`${t('service-portal.checkbox')} A`}
                          checked={field.value}
                          onChange={field.onChange}
                        />
                      )}
                    />
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <Controller
                      name="checkbox_b"
                      control={control}
                      render={({ field }) => (
                        <Checkbox
                          testId="checkbox-b"
                          label={`${t('service-portal.checkbox')} B`}
                          checked={field.value}
                          onChange={field.onChange}
                        />
                      )}
                    />
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <Controller
                      name="checkbox_c"
                      control={control}
                      render={({ field }) => (
                        <Checkbox
                          testId="checkbox-c"
                          label={`${t('service-portal.checkbox')} C`}
                          checked={field.value}
                          onChange={field.onChange}
                        />
                      )}
                    />
                  </Grid>
                </FieldGroupRow>
              </FieldGroupContainer>
            </>
          )}
        </Box>
      </PageLayoutContainer>
    </form>
  )
}
