import { LicenseManager } from 'ag-grid-enterprise'
import { FC, useEffect, useMemo, useState } from 'react'
import { ErrorBoundary } from 'react-error-boundary'
import { I18nextProvider, useTranslation } from 'react-i18next'
import { BrowserRouter, Routes, useNavigate } from 'react-router-dom'

import { Biotech, Grid3x3, Rectangle } from '@mui/icons-material'
import { ThemeProvider } from '@mui/material'

import packageJSON from '@/../package.json'

import AddressablePage from '@shared/addressable-page-manager/src/AddressablePage'
import AddressablePageManager from '@shared/addressable-page-manager/src/AddressablePageManager'
import { api, User } from '@shared/api'
import { ErrorHandler } from '@shared/components'
import AppTheme from '@shared/design'
import { NotificationProvider } from '@shared/hooks'
import { AuthorizationProvider } from '@shared/hooks/useAuthorization'
import { EnforceLoginProvider } from '@shared/hooks/useEnforceLogin'
import {
  FtpPortalHubCommunicationProvider,
  useFtpPortalHubCommunication,
} from '@shared/hooks/useFtpPortalHubCommunication'
import {
  DashboardIcon,
  DocumentIcon,
  SettingsIcon,
  SearchIcon,
} from '@shared/icons'

import i18n from './i18n'
import { ReactTestingLibrarySamples } from './pages/react-testing-library-samples/ReactTestingLibrarySamples'
import { SampleAdd } from './pages/sample-add/SampleAdd'
import { SampleDashboard } from './pages/sample-dashboard/SampleDashboard'
import { SampleEdit } from './pages/sample-edit/SampleEdit'
import { SampleGrid } from './pages/sample-grid/SampleGrid'
import { SampleModal } from './pages/sample-modal/SampleModal'
import { SampleView } from './pages/sample-view/SampleView'
import { UISamples } from './pages/ui-samples/UISamples'

LicenseManager.setLicenseKey(process.env.AG_GRID_LICENSE)

const ServicePortalApp: FC = () => {
  const { t } = useTranslation()

  const {
    setNavigatorMenu,
    setPortalVersion,
    setAppShowSearchBar,
    setAppShowLocationBar,
    setApplicationBanner,
  } = useFtpPortalHubCommunication()

  const navigate = useNavigate()

  const [user, setUser] = useState<User | null>(null)

  const [addressablePageManager, setAddressablePageManager] =
    useState<AddressablePageManager>()

  const pages = useMemo(
    () => [
      // This first route is just for testing purposes
      new AddressablePage(
        t('service-portal.sample-dashboard'),
        <SampleDashboard />,
        [],
        '/service',
        <DashboardIcon />
      ),
      new AddressablePage(
        t('service-portal.sample-grid'),
        <SampleGrid />,
        [],
        '/service/sample-grid',
        <Grid3x3 />
      ),
      new AddressablePage(
        t('service-portal.ui-samples'),
        <UISamples />,
        [],
        '/service/ui-samples',
        <Biotech />
      ),
      new AddressablePage(
        t('service-portal.sample-view'),
        <SampleView />,
        ['v1.partner.merchants.get'],
        '/service/sample-view/:merchantId',
        <SearchIcon />,
        true
      ),
      new AddressablePage(
        t('service-portal.sample-forms'),
        <></>,
        [],
        undefined,
        <DocumentIcon />
      )
        .addNestedPage(
          new AddressablePage(
            t('service-portal.sample-add'),
            <SampleAdd />,
            [],
            '/service/sample-add'
          )
        )
        .addNestedPage(
          new AddressablePage(
            t('service-portal.sample-edit'),
            (
              <SampleEdit
                user={{
                  first_name: 'Test First Name',
                  last_name: 'Test Last Name',
                  email: 'test_email@mail.com',
                }}
              />
            ),
            [],
            '/service/sample-edit'
          )
        )
        .addNestedPage(
          new AddressablePage(
            t('service-portal.react-testing-library-samples'),
            <ReactTestingLibrarySamples />,
            [],
            '/service/rtl-samples'
          )
        ),
      new AddressablePage(
        t('service-portal.sample-modal-double-nested'),
        <></>,
        [],
        undefined,
        <Rectangle />
      ).addNestedPage(
        new AddressablePage(t('...'), <></>, [], undefined).addNestedPage(
          new AddressablePage(
            t('service-portal.sample-modal'),
            <SampleModal />,
            [],
            '/service/sample-modal'
          )
        )
      ),
    ],
    []
  )

  useEffect(() => {
    api
      .service('users')
      .authorize()
      .then(setUser)
      .catch((error) => {
        console.error('ERROR GETTING/SETTING USER DATA:', error.toString())
      })

    setPortalVersion({ version: packageJSON?.version })

    // TODO: Setup Correct Application Banner Icons
    setApplicationBanner({
      expanded: SettingsIcon,
      collapsed: SettingsIcon,
    })

    setAppShowSearchBar(false)
    setAppShowLocationBar(false)
  }, [])

  useEffect(() => {
    if (!user) return
    setAddressablePageManager(new AddressablePageManager(pages, user))
  }, [user])

  useEffect(() => {
    if (!addressablePageManager) return

    setNavigatorMenu({
      menuItems: addressablePageManager.generateAllNavigatorMenuItems(),
    })

    const newRoute = addressablePageManager.handleLocationChange(
      window.location.pathname
    )

    newRoute ? navigate(newRoute) : null
  }, [addressablePageManager])

  if (!addressablePageManager) return null

  return (
    <AuthorizationProvider user={user}>
      <Routes>{addressablePageManager.renderAllAddressablePages()}</Routes>
    </AuthorizationProvider>
  )
}

const ServicePortal: FC = () => (
  <ErrorBoundary FallbackComponent={ErrorHandler}>
    <FtpPortalHubCommunicationProvider>
      <I18nextProvider i18n={i18n}>
        <ThemeProvider theme={AppTheme}>
          <EnforceLoginProvider redirectToLogin>
            <BrowserRouter>
              <NotificationProvider>
                <ServicePortalApp />
              </NotificationProvider>
            </BrowserRouter>
          </EnforceLoginProvider>
        </ThemeProvider>
      </I18nextProvider>
    </FtpPortalHubCommunicationProvider>
  </ErrorBoundary>
)

export default ServicePortal
