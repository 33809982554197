import { DateTime } from 'luxon'
import { FC, useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import { Box, Grid, Paper } from '@mui/material'

import BarChart from '@shared/components/charts/BarChart'
import BaseChart from '@shared/components/charts/BaseChart'
import LineChart from '@shared/components/charts/LineChart'
import { useFtpPortalHubCommunication } from '@shared/hooks'
import { CustomPoint } from '@shared/types'

type BarData = {
  month: string
  approved: number
  declined: number
}

const SampleBarChart: FC = () => {
  const { t } = useTranslation()

  const colorsByChartEntity: Record<'approved' | 'declined', string> = {
    approved: '#83bf9f',
    declined: '#f7955a',
  }

  const sampleBarChartData: BarData[] = [
    {
      month: t('service-portal.charts.jan'),
      approved: 1,
      declined: 1,
    },
    {
      month: t('service-portal.charts.feb'),
      approved: 2,
      declined: 2,
    },
    {
      month: t('service-portal.charts.mar'),
      approved: 3,
      declined: 3,
    },
    {
      month: t('service-portal.charts.apr'),
      approved: 4,
      declined: 4,
    },
    {
      month: t('service-portal.charts.may'),
      approved: 5,
      declined: 5,
    },
    {
      month: t('service-portal.charts.jun'),
      approved: 6,
      declined: 6,
    },
    {
      month: t('service-portal.charts.jul'),
      approved: 7,
      declined: 7,
    },
    {
      month: t('service-portal.charts.aug'),
      approved: 8,
      declined: 8,
    },
    {
      month: t('service-portal.charts.sep'),
      approved: 9,
      declined: 9,
    },
    {
      month: t('service-portal.charts.oct'),
      approved: 10,
      declined: 10,
    },
    {
      month: t('service-portal.charts.nov'),
      approved: 11,
      declined: 11,
    },
    {
      month: t('service-portal.charts.dec'),
      approved: 12,
      declined: 12,
    },
  ]

  return (
    <Box data-testid="bar-chart-container">
      <BaseChart
        title={t('service-portal.sample-bar-chart')}
        tooltip={t('service-portal.sample-bar-chart')}
      >
        <BarChart
          data={sampleBarChartData ?? []}
          keys={['approved', 'in_progress', 'declined', 'withdrawn', 'closed']}
          groupMode="stacked"
          indexBy="month"
          colors={(chartEntity) => colorsByChartEntity[chartEntity.id]}
          margin={{
            top: 50,
            bottom: 110,
            left: 22,
          }}
          padding={0.3}
          enableGridY={false}
          enableLabel={false}
          legends={[
            {
              dataFrom: 'keys',
              data: [
                {
                  id: 'approved',
                  label: t('common.approved'),
                  color: colorsByChartEntity['approved'],
                },
                {
                  id: 'declined',
                  label: t('common.declined'),
                  color: colorsByChartEntity['declined'],
                },
              ],
              itemTextColor: '#000',
              anchor: 'top-left',
              direction: 'row',
              translateX: -22,
              translateY: -35,
              itemsSpacing: 10,
              itemDirection: 'left-to-right',
              itemWidth: 80,
              itemHeight: 20,
              symbolShape: 'circle',
              symbolSize: 10,
            },
          ]}
        />
      </BaseChart>
    </Box>
  )
}

type LineChartData = {
  id: string
  data: {
    id: string
    x: string
    y: number
    date: DateTime<true> | DateTime<false>
  }[]
}

const SampleLineChart: FC = () => {
  const { t } = useTranslation()

  const colorsByChartEntity: Record<string, string> = {
    A: '#82BE9F',
    B: '#4197CB',
  }

  const sampleLineChartData: LineChartData[] = [
    {
      id: 'A',
      data: [
        {
          id: 'A1',
          x: t('service-portal.charts.jan'),
          y: 1,
          date: DateTime.utc(),
        },
        {
          id: 'A2',
          x: t('service-portal.charts.feb'),
          y: 2,
          date: DateTime.utc(),
        },
        {
          id: 'A3',
          x: t('service-portal.charts.mar'),
          y: 3,
          date: DateTime.utc(),
        },
        {
          id: 'A4',
          x: t('service-portal.charts.apr'),
          y: 4,
          date: DateTime.utc(),
        },
        {
          id: 'A5',
          x: t('service-portal.charts.may'),
          y: 5,
          date: DateTime.utc(),
        },
        {
          id: 'A6',
          x: t('service-portal.charts.jun'),
          y: 6,
          date: DateTime.utc(),
        },
        {
          id: 'A7',
          x: t('service-portal.charts.jul'),
          y: 7,
          date: DateTime.utc(),
        },
        {
          id: 'A8',
          x: t('service-portal.charts.aug'),
          y: 8,
          date: DateTime.utc(),
        },
        {
          id: 'A9',
          x: t('service-portal.charts.sep'),
          y: 9,
          date: DateTime.utc(),
        },
        {
          id: 'A10',
          x: t('service-portal.charts.oct'),
          y: 10,
          date: DateTime.utc(),
        },
        {
          id: 'A11',
          x: t('service-portal.charts.nov'),
          y: 11,
          date: DateTime.utc(),
        },
        {
          id: 'A12',
          x: t('service-portal.charts.dec'),
          y: 12,
          date: DateTime.utc(),
        },
      ],
    },
    {
      id: 'B',
      data: [
        {
          id: 'B1',
          x: t('service-portal.charts.jan'),
          y: 2,
          date: DateTime.utc(),
        },
        {
          id: 'B2',
          x: t('service-portal.charts.feb'),
          y: 4,
          date: DateTime.utc(),
        },
        {
          id: 'B3',
          x: t('service-portal.charts.mar'),
          y: 6,
          date: DateTime.utc(),
        },
        {
          id: 'B4',
          x: t('service-portal.charts.apr'),
          y: 8,
          date: DateTime.utc(),
        },
        {
          id: 'B5',
          x: t('service-portal.charts.may'),
          y: 10,
          date: DateTime.utc(),
        },
        {
          id: 'B6',
          x: t('service-portal.charts.jun'),
          y: 12,
          date: DateTime.utc(),
        },
        {
          id: 'B7',
          x: t('service-portal.charts.jul'),
          y: 14,
          date: DateTime.utc(),
        },
        {
          id: 'B8',
          x: t('service-portal.charts.aug'),
          y: 16,
          date: DateTime.utc(),
        },
        {
          id: 'B9',
          x: t('service-portal.charts.sep'),
          y: 18,
          date: DateTime.utc(),
        },
        {
          id: 'B10',
          x: t('service-portal.charts.oct'),
          y: 20,
          date: DateTime.utc(),
        },
        {
          id: 'B11',
          x: t('service-portal.charts.nov'),
          y: 22,
          date: DateTime.utc(),
        },
        {
          id: 'B12',
          x: t('service-portal.charts.dec'),
          y: 24,
          date: DateTime.utc(),
        },
      ],
    },
  ]

  const getColorByChartEntity = (chartEntity: { id: string }): string =>
    colorsByChartEntity[chartEntity.id]

  return (
    <Box data-testid="line-chart-container">
      <BaseChart
        title={t('service-portal.sample-line-chart')}
        tooltip={t('service-portal.sample-line-chart')}
      >
        <LineChart
          data={sampleLineChartData ?? []}
          colors={getColorByChartEntity}
          margin={{
            top: 50,
            bottom: 50,
            left: 80,
            right: 15,
          }}
          tooltip={({ point }) => {
            const { data } = point as CustomPoint
            return <Paper elevation={3}>{data.y}</Paper>
          }}
        />
      </BaseChart>
    </Box>
  )
}

export const SampleDashboard: FC = () => {
  const { t } = useTranslation()
  const { setAppBarTitle } = useFtpPortalHubCommunication()

  useEffect(() => {
    setAppBarTitle(t('service-portal.sample-dashboard'))
  }, [])

  return (
    <section>
      <Grid container spacing={2}>
        <Grid item xs={12} md={12} lg={6}>
          <SampleBarChart />
        </Grid>

        <Grid item xs={12} md={12} lg={6}>
          <SampleLineChart />
        </Grid>
      </Grid>
    </section>
  )
}
