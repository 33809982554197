import React from 'react'

import { InfoOutlined } from '@mui/icons-material'
import { Box, Grid, Tooltip, useMediaQuery } from '@mui/material'

interface BaseChartProps {
  title: string
  tooltip?: string
  extraButton?: React.ReactNode
  filter?: React.ReactNode
  transactionsInfo?: React.ReactNode
  children: React.ReactNode
  applyBorder?: boolean
  guidingId?: string
}
const BaseChart = ({
  title,
  tooltip,
  extraButton,
  filter,
  transactionsInfo,
  children,
  applyBorder,
  guidingId,
}: BaseChartProps) => {
  const isLowResolution = useMediaQuery('(max-height: 800px)')

  return (
    <Box
      sx={{
        border: !applyBorder ? null : '1px solid #ccc',
        borderRadius: !applyBorder ? null : '6px',
        bgcolor: '#fff',
        height: isLowResolution ? '80vh' : '50vh',
        width: '100%',
        overflow: 'hidden',
        padding: '1rem 3rem 8rem 2rem',
        '@media (max-width: 1270px)': {
          padding: '1rem 3rem 9rem 2rem',
        },
      }}
      data-guiding-id={guidingId}
    >
      <Grid
        container
        spacing={2}
        justifyContent="space-between"
        alignItems="center"
      >
        <Grid item xs={8}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <h2 style={{ margin: 0, padding: 0 }}>{title}</h2>
            {tooltip && (
              <Tooltip title={tooltip}>
                <InfoOutlined
                  style={{
                    color: '#ccc',
                    fontSize: '1rem',
                    marginLeft: '0.5rem',
                  }}
                />
              </Tooltip>
            )}
          </div>
        </Grid>
        <Grid
          item
          xs={4}
          style={{
            textAlign: 'right',
          }}
        >
          {extraButton}
        </Grid>
        <Grid item xs={12}>
          {transactionsInfo}
        </Grid>
        {filter && (
          <Grid item xs={4}>
            {filter}
          </Grid>
        )}
      </Grid>
      {children}
    </Box>
  )
}

export default BaseChart
