import { FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import { tss } from 'tss-react/mui'

import { CircularProgress } from '@mui/material'

import { api, Merchant } from '@shared/api'
import {
  FieldGroupContainer,
  FieldGroupRow,
  FieldGroupViewItem,
  HasPermission,
  PageLayoutContainer,
  PageLayoutContainerMain,
  UserNotAllowed,
} from '@shared/components'
import { useFtpPortalHubCommunication, useNotification } from '@shared/hooks'
import { formatDatetime } from '@shared/utils'

const EMPTY_FIELD_PLACEHOLDER = '-'

const useStyles = tss.withName('SampleView').create(() => ({
  loadingContainer: {
    display: 'grid',
    placeContent: 'center',
    minHeight: '700px',
    width: '100%',
  },
}))

export const SampleView: FC = () => {
  const { t } = useTranslation()
  const { classes } = useStyles()
  const { setAppBarTitle } = useFtpPortalHubCommunication()
  const { setNotification } = useNotification()
  const { merchantId } = useParams()
  const navigate = useNavigate()

  const [merchant, setMerchant] = useState<Merchant | null>(null)

  const [isLoading, setIsLoading] = useState(!merchant)

  const getMerchant = async () => {
    if (!merchantId) return
    setIsLoading(true)

    try {
      await api.service('merchants').get(merchantId).then(setMerchant)
      setIsLoading(false)
    } catch (_) {
      setNotification({
        type: 'error',
        label: t('common.something-went-wrong'),
      })
    }
  }

  // This useEffect is called only once when the component is mounted
  // and it sets the AppBar at the top of the page
  useEffect(() => {
    // This is static, since it's only called once
    setAppBarTitle(
      `${t('service-portal.sample-view')} (${t(
        'common.merchant-id'
      )}: ${merchantId})`, // This sets the AppBar title
      undefined, // This means that the title won't have a tooltip
      [t('Merchants'), t('View')], // This sets the breadcrumbs
      '/service/sample-grid' // This sets the link to the previous page
    )
  }, []) // This empty array means that this useEffect will only be called once

  useEffect(() => {
    if (!merchantId) {
      navigate('/service/sample-grid')
      return
    }

    getMerchant()
  }, [merchantId])

  return (
    <HasPermission
      permission="v1.partner.merchants.get"
      unauthorizedComponent={<UserNotAllowed />}
    >
      <>
        <PageLayoutContainer>
          {isLoading && (
            <div className={classes.loadingContainer}>
              <CircularProgress data-testid="loading-icon" />
            </div>
          )}

          {!isLoading && !!merchant && (
            <PageLayoutContainerMain isFullWidth>
              <FieldGroupContainer title={t('service-portal.merchant-info')}>
                <FieldGroupRow>
                  <FieldGroupViewItem
                    title={t('common.company-id')}
                    value={merchant.company_id ?? EMPTY_FIELD_PLACEHOLDER}
                  />

                  <FieldGroupViewItem
                    title={t('common.mid')}
                    value={merchant.merchant_number ?? EMPTY_FIELD_PLACEHOLDER}
                  />
                </FieldGroupRow>

                <FieldGroupRow>
                  <FieldGroupViewItem
                    title={t('common.dba')}
                    value={merchant.dba_name ?? EMPTY_FIELD_PLACEHOLDER}
                  />

                  <FieldGroupViewItem
                    title={t('common.received')}
                    value={
                      merchant.received_ts
                        ? formatDatetime(merchant.received_ts)
                        : EMPTY_FIELD_PLACEHOLDER
                    }
                  />
                </FieldGroupRow>

                <FieldGroupRow>
                  <FieldGroupViewItem
                    title={t('common.phone')}
                    value={
                      merchant.customer_service_phone ?? EMPTY_FIELD_PLACEHOLDER
                    }
                  />
                </FieldGroupRow>
              </FieldGroupContainer>
            </PageLayoutContainerMain>
          )}
        </PageLayoutContainer>
      </>
    </HasPermission>
  )
}
